.modal-body {
  padding: 0px 30px;
}

.ids-modal-title h3 {
  color: #003468; /* Prussian Blue */
}

.ids-modal-title-close-button svg {
  display: none;
}

.modal-button-container {
  display: flex;
  position: static;
  height: auto;
  border-width: 0;
  padding-bottom: 16px;
  padding-right: 60px;
  justify-content: end;
}

.modal-button {
  background-color: #ffc20e !important; /* Golden Yellow Color */
  color: #001b35 !important;
  margin-left: 20px !important;
  min-width: 100px !important;
  display: inline-block;
  font-size: 16px;
  font-weight: 700;
}

@media (max-width: 479px) {
  .modal-body {
    padding: 24px 16px;
  }

  .modal-button-container {
    background: hsla(0, 0%, 93.3%, 0.95);
    justify-content: center;
    align-items: center;
    grid-gap: 1.6rem;
    gap: 1.6rem;
    height: auto;
    padding: 0.8rem 1.6rem;
    position: absolute;
    bottom: 0;
    left: 0;
    right: 0;
    border-width: 0;
  }

  #ids-modal-title-confirmation-modal {
    padding-top: 8px;
    padding-bottom: 8px;
    background-color: #001b35;
    color: white;
  }

  .ids-modal-title h3 {
    padding-top: 3px;
    color: white;
    font-size: large;
  }
}
