.recent-text-grid-container {
  width: 85%;
  padding: 16px 0 16px 16px !important;
  margin: auto !important;
  box-sizing: border-box;
}

.recent-text {
  display: flex;
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 600;
  line-height: 17.58px;
  letter-spacing: 0.2px;
  color: #4a4a4a !important;
}
.recent-item-grid-container {
  width: 85%;
  margin: auto !important;
}
.recent-scan-item-background {
  background-color: #ffffff !important;
  margin: 0 0 0 0 !important;
}
.recent-scan-item-wo-text {
  font-family: 'Roboto', sans-serif;
  font-size: 15px;
  font-weight: 600;
  color: #000000;
  margin-bottom: 4px;
}

.recent-scan-item-text {
  font-size: 15px !important;
  font-weight: 400 !important;
  color: #4a4a4a !important;
}

.recent-scans-list .ids-list-item-content {
  background-color: transparent !important;

  border-style: solid;
  border-color: #b2b2b2;
  border-width: 1px;
  /* Same border as list items for consistency */
}

.recent-scans-list .ids-list-items-container {
  max-height: 65vh;
  overflow: auto;
}
