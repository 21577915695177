.cert-buttons {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 10px;
  gap: 13px;
  text-align: center;
  position: sticky;
  bottom: 0;
  background-color: #f4f4f4;
}

.cert-buttons-save {
  width: 50%;
  margin: 10px;
}

.cert-buttons-finish {
  width: 50%;
  margin: 10px;
}

.cert-buttons-finish-color {
  background-color: #ffc20e !important;
  color: black !important;
}

.error_card {
  text-align: center;
  padding: 15px;
}

.alert-error {
  margin: 5px;
}

/* FQE Title */
#cert-assist-questions-and-announcements-root p:first-child {
  padding-left: 27px;
  font-size: 20px;
  font-weight: 600;
}

/* FQE Container */
.Question__Container-qjdhjw-9 {
  padding-left: 20px;
  padding-right: 20px;
  background-color: transparent;
}

/* FQE question Buttons */
.Question__QuestionText-qjdhjw-7,
.AnswerLabel__ButtonLabel-sc-1pvwchk-3,
.AnswerNotes__NotesInput-vofv4e-2,
.AnswerButton-sc-1ymy5p1-0 {
  font-size: 16px;
}

.Question__QuestionText-qjdhjw-7 {
  font-weight: 700;
  padding-left: 2px;
}

.AnswerLabel__ButtonLabel-sc-1pvwchk-3,
.AnswerButton-sc-1ymy5p1-0 {
  font-weight: 600;
}

.AnswerNotes__NotesInput-vofv4e-2 {
  font-weight: 400;
}

/* Inner Title of dropdown */
.NestedAnswers__NestedQuestionText-sc-1nze12h-5 {
  color: #005ba8 !important;
}

/* Inner Dashed line of dropdown */
.gOBStz.active {
  border-top: 1px dashed #000;
}

/* Chevron svg of dropdown */
.NestedAnswers__ButtonContainer-sc-1nze12h-3.cuCwhe.active svg path {
  fill: #333;
}
