.scanner-page {
  min-height: 100vh;
  background: #ffffff;
}

.scanner-grid-container {
  width: 85%;
  margin: auto !important;
}
.scanner-parent-grid-container {
  width: 100%;
  margin: auto !important;
}

.header-container {
  padding: 16px 0 16px 4px !important;
}

.header-text {
  display: flex;
  color: #25292e !important; /* Dark Grey */
}

.golden-button:not(.Mui-disabled),
.golden-button:not(.Mui-disabled):hover {
  color: white;
  background: #005ba8; /* Golden Yellow Color */
}

.barcode-container {
  margin: auto;
}
