/* Error Message Bar Styling */
.error-message-bar {
  width: 100%;
  background-color: rgb(252, 204, 192) !important;
  align-content: center;
  margin: 16px;
  padding: 9px 16px 9px 16px !important;
  justify-content: end;
  border-radius: 4px;
}

.error-message-bar-grid-container {
  width: 85%;
  margin: auto !important;
}

.error-message-bar-icon {
  width: 15px;
  height: 15px;
  margin-top: 1px;
  position: absolute;
  color: rgb(144, 43, 0);
}

.error-message-bar-text {
  color: rgb(144, 43, 0) !important;
  text-align: left;
  font-weight: 500 !important;
  font-size: 14px;
  padding-left: 24px;
  align-content: center;
}
